import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import Layout from 'components/Layout'
import image1 from 'images/project-21b.png'
import image2 from 'images/project-21c.png'

const Page = () => (
  <Layout>
    <Helmet
      title="Building affordable rentals"
      meta={[
        {
          name: 'description',
          content: 'In the Hutt Valley, two new Waiwhetu townhouses are under construction to offer affordable rental accommodation for low-income families.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Building affordable rentals"
      intro="In the Hutt Valley, two new Waiwhetu townhouses are under construction to offer affordable rental accommodation for low-income families."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            In the Hutt Valley, two new Waiwhetu townhouses are under construction to offer affordable rental accommodation for low-income families. It&rsquo;s part of a new initiative the trust explored last year with the successful construction of two modern flats in Naenae.
          </Paragraph>
          <Paragraph>
            The Waiwhetu accommodation uses an identical layout to the units built in Naenae, featuring two bedrooms in each dwelling &ndash; ideal fora small family. The design should prove economic, particularly during this period of increased building costs and despite the wet winter, progress hasn&rsquo;t been interrupted.
          </Paragraph>
          <Paragraph>
            We are grateful to our building sponsors: Carters have worked hard to ensure there are no supply issues on the job; Metalcraft roofing have provided roof and wall cladding, subcontractors Geeves Scaffolding, NME electrical, and Toptech plumbing have contributed specialist services; and Dulux paint will provide supplies for the next stage.
          </Paragraph>
          <Paragraph>
            Everyone has worked hard to ensure no building holdups and the trust is optimistic the buildings will be ready in time for Christmas 2022.
          </Paragraph>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
